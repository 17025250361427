input[type='range'] {
    --thumbSize: 20px;
    --trackSize: 2px;
    --thumbBg: #000;
    --trackBg: #000;
    --progressBg: #00c878;
    --webkitProgressPercent: 0%;
}

input[type='range'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: var(--trackSize);
    padding: 10px 0;
    width: 100%;
    margin: 0;
    cursor: pointer;
    background-color: transparent;
}
input[type='range']:focus {
    outline: none;
}

input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: var(--thumbSize);
    height: var(--thumbSize);
    background-color: var(--thumbBg);
    border-radius: calc(var(--thumbSize) / 2);
    border: 2px solid #00c878;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
    cursor: pointer;
}

input[type='range']::-webkit-slider-runnable-track {
    height: var(--trackSize);
    background-image: linear-gradient(
        90deg,
        var(--progressBg) var(--webkitProgressPercent),
        var(--trackBg) var(--webkitProgressPercent)
    );
    border-radius: calc(var(--trackSize) / 3);
}

#grapBtn .percentValues {
    position: absolute;
    white-space: nowrap;
    font-size: 12px;
    color: #969696;
    top: 20px;
    left: 50%;
    transform: translate(-50%, 0);
}
#grapBtn:last-child .percentValues {
    transform: translate(-80%, 0);
}
#grapBtn:first-child .percentValues {
    left: 0px;
    transform: translate(0%, 0);
}
