@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply dark:bg-black dark:text-white antialiased;
    }

    button {
        @apply dark:bg-darkpool-green rounded-md;
    }

    select {
        @apply bg-black border border-darkpool-light1 rounded-md focus:border-transparent focus:ring-0;
    }

    [type='text'],
    [type='number'] {
        @apply dark:bg-black border-none text-2xl p-0 focus:border-transparent focus:ring-0;
    }

    .box {
        @apply rounded-lg dark:bg-darkpool-dimmed mt-6 max-w-xl mx-auto p-4 lg:mt-0 lg:col-span-5 lg:p-6;
    }

    .box-without-width {
        @apply rounded-lg dark:bg-darkpool-dimmed mt-6 mx-auto p-4 lg:mt-0 lg:col-span-5 lg:p-6;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.tooltip {
    @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
    @apply visible z-50 bg-black dark:bg-black;
}
